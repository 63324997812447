.OUTLINE {
    stroke-linejoin: round;
    stroke: #a1a1aa;
    stroke-width: 2;
}

.si-do:hover {
    fill: #8e99a8;
}

.si-do {
    fill: #94a3b8;
    cursor: pointer;
}

.si-do.selected {
    fill: #818cf8;
}

.TEXT {
    cursor: pointer;
    fill: #404040;
    font-size: 24px;
    font-weight: bold;
    text-anchor: middle;
    alignment-baseline: middle;
}

.TEXT.selected {
    fill: red;
}

