//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//

// Theme colors
// Primary
$primary: #009ef7;
$primary-active: #0095e8;
$primary-light: #f1faff;
$primary-light-dark: #212e48;
$primary-inverse: #ffffff;

// Success
$success: #50cd89;
$success-active: #47be7d;
$success-light: #e8fff3;
$success-light-dark: #1c3238;
$success-inverse: #ffffff;

// Info
$info: #7239ea;
$info-active: #5014d0;
$info-light: #f8f5ff;
$info-light-dark: #2f264f;
$info-inverse: #ffffff;

// Danger
$danger: #f1416c;
$danger-active: #d9214e;
$danger-light: #fff5f8;
$danger-light-dark: #3a2434;
$danger-inverse: #ffffff;

// Warning
$warning: #ffc700;
$warning-active: #f1bc00;
$warning-light: #fff8dd;
$warning-light-dark: #392f28;
$warning-inverse: #ffffff;


//ag-grdi
.ag-theme-alpine {
  --ag-header-height: 30px;
  --ag-header-foreground-color: white !important;
  --ag-header-background-color: rgb(7 37 21) !important;
  --ag-header-cell-hover-background-color: rgb(80, 40, 140) !important;
  --ag-header-cell-moving-background-color: rgb(80, 40, 140);
  --ag-row-border-width:0px !important;

  .ag-header-group-cell {
    font-weight: bold;
  }

  .ag-header-cell {
    font-size: 14px;
  }
  .ag-header-cell-label {
    justify-content: center; // 텍스트를 가운데로 정렬
  }

}

.checkmark {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #f43f5e;
  stroke-miterlimit: 10;
  margin: 10% auto;
  box-shadow: inset 0px 0px 0px #7ac142;
  animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;

  &__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: #f43f5e;
    fill: none;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
  }

  &__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
  }
}

